import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheNavBar = _resolveComponent("TheNavBar")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_AuthChallenge = _resolveComponent("AuthChallenge")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheNavBar),
    _createVNode(_component_Modal),
    _createVNode(_component_Dialog),
    _createVNode(_component_AuthChallenge),
    _createVNode(_component_router_view)
  ], 64))
}