import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "control has-icons-left has-icons-right mb-3" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "icon is-small is-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$gettext("Enter your password")), 1),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        class: "input",
        type: "password",
        placeholder: _ctx.$gettext('Your password'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.isPasswordValid && _ctx.isPasswordValid(...args))),
        ref: "creds",
        onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.submitPassword && _ctx.submitPassword(...args)), ["enter"]))
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.password]
      ]),
      _createElementVNode("span", _hoisted_5, [
        _createVNode(_component_fa_icon, { icon: "key" })
      ])
    ]),
    _createElementVNode("button", {
      class: "button is-primary",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitPassword()))
    }, _toDisplayString(_ctx.$gettext("Send")), 1)
  ]))
}