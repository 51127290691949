import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3501e3fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "icon"
}
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "control has-icons-left has-icons-right" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "icon is-small is-left" }
const _hoisted_6 = {
  key: 0,
  class: "field"
}
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "control has-icons-left has-icons-right" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = { class: "icon is-small is-left" }
const _hoisted_11 = {
  key: 1,
  class: "help is-danger"
}
const _hoisted_12 = {
  key: 2,
  class: "help is-danger"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "mr-2 mt-5" }
const _hoisted_15 = { class: "control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      class: "label edit-pin",
      onClick: _cache[0] || (_cache[0] = ($event: any) => {;(_ctx.editMode = !_ctx.editMode), _ctx.setFocusPin()})
    }, [
      _createTextVNode(_toDisplayString(_ctx.$gettext("configure PIN code")) + " ", 1),
      (_ctx.isConfigured)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(_component_fa_icon, {
              icon: "edit",
              class: "fa-large"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.editMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createElementVNode("input", {
                class: "input",
                type: "password",
                onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onlyNumbers && _ctx.onlyNumbers(...args))),
                placeholder: _ctx.$gettext('PIN code'),
                pattern: "[0-9]{4}",
                inputmode: "numeric",
                maxlength: "4",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pin) = $event)),
                onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showConfirmPin && _ctx.showConfirmPin(...args))),
                ref: "pin"
              }, null, 40, _hoisted_4), [
                [_vModelText, _ctx.pin]
              ]),
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_fa_icon, { icon: "key" })
              ])
            ])
          ]),
          (_ctx.showConfPin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$gettext("PIN code confirmation")), 1),
                _createElementVNode("div", _hoisted_8, [
                  _withDirectives(_createElementVNode("input", {
                    class: "input",
                    type: "password",
                    onKeypress: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onlyNumbers && _ctx.onlyNumbers(...args))),
                    placeholder: _ctx.$gettext('PIN code confirmation'),
                    pattern: "[0-9]{4}",
                    inputmode: "numeric",
                    maxlength: "4",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pinConf) = $event)),
                    onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.isPinValid && _ctx.isPinValid(...args)))
                  }, null, 40, _hoisted_9), [
                    [_vModelText, _ctx.pinConf]
                  ]),
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_fa_icon, { icon: "key" })
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.errors.pinSize)
            ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.errors.pinSize), 1))
            : _createCommentVNode("", true),
          (_ctx.errors.pinConfirmation)
            ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.errors.pinConfirmation), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            (_ctx.isPinValid())
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("button", {
                        class: "button is-primary",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.submitConfig())),
                        ref: "submit"
                      }, _toDisplayString(_ctx.$gettext("Save")), 513)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}