import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d6e2486"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "reset-password" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "field mb-5 has-addons" }
const _hoisted_7 = { class: "control has-icons-left is-expanded" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "icon is-small is-left" }
const _hoisted_10 = { class: "reset-buttons" }
const _hoisted_11 = { class: "control has-text-centered" }
const _hoisted_12 = {
  type: "submit",
  class: "button is-login"
}
const _hoisted_13 = {
  key: 0,
  class: "has-text-danger has-text-centered mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$config.loginLogoUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.$config.loginLogoUrl,
              class: "pt-2 pb-5"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$gettext(
              "Please enter the email address associated with your account below. We'll send you an email with instructions on how to reset your password"
            )), 1),
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                class: "input",
                placeholder: _ctx.$gettext('Email')
              }, null, 8, _hoisted_8), [
                [
                  _vModelText,
                  _ctx.email,
                  void 0,
                  { trim: true }
                ]
              ]),
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_fa_icon, { icon: "envelope" })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_11, [
                _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.$gettext("Reset password")), 1)
              ])
            ])
          ]),
          (_ctx.fail)
            ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.fail), 1))
            : _createCommentVNode("", true)
        ], 32)
      ])
    ]),
    _createVNode(_component_router_view)
  ]))
}