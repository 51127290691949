import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.mapUrl)
    ? (_openBlock(), _createElementBlock("iframe", {
        key: 0,
        id: "iframe",
        width: "100%",
        height: "100%",
        src: _ctx.mapUrl,
        frameborder: "0",
        marginheight: "0",
        marginwidth: "0",
        allow: "fullscreen",
        style: {"overflow":"hidden","overflow-x":"hidden","overflow-y":"hidden","height":"100%","width":"100%","position":"absolute","top":"0px","left":"0px","right":"0px","bottom":"0px"}
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}