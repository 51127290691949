import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "control has-icons-left has-icons-right" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "icon is-small is-left" }
const _hoisted_6 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$gettext("PIN code")), 1),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          class: "input",
          type: "password",
          onKeypress: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onlyNumbers && _ctx.onlyNumbers(...args))),
          placeholder: _ctx.$gettext('PIN code'),
          pattern: "[0-9]{4}",
          inputmode: "numeric",
          maxlength: "4",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pin) = $event)),
          onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.isPinValid && _ctx.isPinValid(...args))),
          ref: "creds",
          autocomplete: "new-password"
        }, null, 40, _hoisted_4), [
          [_vModelText, _ctx.pin]
        ]),
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_fa_icon, { icon: "key" })
        ])
      ])
    ]),
    (this.errors.pinSize)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(this.errors.pinSize), 1))
      : _createCommentVNode("", true)
  ]))
}