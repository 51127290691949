import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1140010f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "is-flex is-justify-content-flex-start"
}
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = {
  key: 0,
  class: "is-size-6 has-text-grey-light"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_ctx.recipient)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["pr-3 is-clickable is-align-items-center is-flex", [_ctx.recipient.is_favorite ? 'is-active' : '']]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.recipient.toggleFavorite()))
        }, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(_component_fa_icon, {
              class: _normalizeClass(!_ctx.recipient.is_favorite ? 'has-text-grey-light' : ''),
              icon: _ctx.recipient.is_favorite ? 'fa fa-star' : 'far fa-star'
            }, null, 8, ["class", "icon"])
          ])
        ], 2),
        _createElementVNode("div", {
          class: "recipient-name is-size-5",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('select', _ctx.recipient)))
        }, [
          _createTextVNode(_toDisplayString(_ctx.recipient.name) + " ", 1),
          (_ctx.recipient.markBackend)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(`${_ctx.recipient.backendId}`), 1))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}