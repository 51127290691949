import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthPref = _resolveComponent("AuthPref")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userAccounts, (account) => {
    return (_openBlock(), _createElementBlock("div", {
      key: account.name
    }, [
      _createVNode(_component_AuthPref, {
        name: account.name,
        handler: account.handler,
        requestCredentials: account.requestCredentials,
        onSaveConfig: _ctx.saveConfig
      }, null, 8, ["name", "handler", "requestCredentials", "onSaveConfig"])
    ]))
  }), 128))
}