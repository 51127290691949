import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d986305e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accounts card custom-card custom-card-padding" }
const _hoisted_2 = {
  key: 1,
  class: "active"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "icon is-small" }
const _hoisted_5 = {
  key: 0,
  class: "notification is-danger is-light"
}
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = {
  key: 1,
  class: "notification is-default notification-no-accounts"
}
const _hoisted_9 = {
  key: 2,
  class: "section-card"
}
const _hoisted_10 = { class: "custom-card-title title-card" }
const _hoisted_11 = {
  key: 2,
  class: "inactive section-card"
}
const _hoisted_12 = { class: "custom-card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BankAccountItem = _resolveComponent("BankAccountItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.activeVirtualAccounts.length)
      ? (_openBlock(), _createBlock(_component_loading, {
          key: 0,
          active: _ctx.accountsLoading,
          "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accountsLoading) = $event)),
          "can-cancel": false,
          "is-full-page": false
        }, null, 8, ["active"]))
      : _createCommentVNode("", true),
    (!_ctx.accountsLoading || _ctx.activeVirtualAccounts.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.refreshBalanceAndTransactions && _ctx.refreshBalanceAndTransactions(...args))),
            title: _ctx.$gettext('Refresh balance and transaction list'),
            class: _normalizeClass(["button is-default is-pulled-right is-rounded refresh", { 'active-refresh-button': _ctx.accountsLoading }])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass({ hide: _ctx.accountsLoading })
            }, _toDisplayString(_ctx.$gettext("Refresh")), 3),
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_fa_icon, {
                class: _normalizeClass({ refreshing: _ctx.accountsLoading }),
                icon: "sync"
              }, null, 8, ["class"])
            ])
          ], 10, _hoisted_3),
          (_ctx.accountsLoadingError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$gettext(
              "An unexpected issue occurred while loading your " +
                "wallet information."
            )) + " " + _toDisplayString(_ctx.$gettext("Sorry for the inconvenience.")), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$gettext(
              "You can try to refresh the page, if the issue " +
                "persists, you may want to contact your " +
                "administrator"
            )), 1)
              ]))
            : (_ctx.totalAccountsLoaded === 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                  _createTextVNode(_toDisplayString(_ctx.$gettext("You don't have any wallet yet,")) + " ", 1),
                  _createVNode(_component_router_link, { to: "/create-account" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$gettext("click here")), 1)
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$gettext("to create one.")), 1)
                ]))
              : (_ctx.activeVirtualAccounts.length !== 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$gettext("your accounts")), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeVirtualAccountsMiddleware, (a) => {
                      return (_openBlock(), _createBlock(_component_BankAccountItem, {
                        bal: a.bal,
                        curr: a.curr,
                        backend: a.backend,
                        type: a.type,
                        active: a.active,
                        subAccounts: a.subAccounts || [],
                        class: _normalizeClass(["mb-5", { selected: a._obj.internalId === _ctx.account?._obj?.internalId }]),
                        onAccountSelected: ($event: any) => (_ctx.$emit('accountSelected', a)),
                        qrcode: true,
                        id: a.id
                      }, {
                        name: _withCtx(() => [
                          _createTextVNode(_toDisplayString(a.name()), 1)
                        ]),
                        _: 2
                      }, 1032, ["bal", "curr", "backend", "type", "active", "subAccounts", "class", "onAccountSelected", "id"]))
                    }), 256))
                  ]))
                : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.inactiveVirtualAccounts.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.$gettext("your pending accounts")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$gettext(
            "The accounts listed below have been subjected to " +
              "a creation request. Once the creation request is" +
              " approved, these accounts will become usable."
          )), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inactiveVirtualAccounts, (account) => {
            return (_openBlock(), _createBlock(_component_BankAccountItem, {
              bal: account.bal,
              curr: account.curr,
              backend: account.backend,
              type: account.type,
              active: account.active
            }, {
              name: _withCtx(() => [
                _createTextVNode(_toDisplayString(account.name()), 1)
              ]),
              _: 2
            }, 1032, ["bal", "curr", "backend", "type", "active"]))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ]))
}