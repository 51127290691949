import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.subHandler !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.subHandler.Ui.Challenge.name), {
          handler: _ctx.subHandler,
          state: _ctx.subState,
          onSubmitInput: _ctx.submitInput
        }, null, 40, ["handler", "state", "onSubmitInput"]))
      ]))
    : _createCommentVNode("", true)
}