import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-025387ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "section-card"
}
const _hoisted_2 = { class: "custom-card-title title-card" }
const _hoisted_3 = { class: "top-up-info" }
const _hoisted_4 = {
  key: 2,
  class: "section-card"
}
const _hoisted_5 = { class: "custom-card-title" }
const _hoisted_6 = { class: "top-up-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_TransactionItem = _resolveComponent("TransactionItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.pendingPaidTopUpList.length && !_ctx.pendingUnpaidTopUpList.length)
      ? (_openBlock(), _createBlock(_component_loading, {
          key: 0,
          active: _ctx.isPendingTopUpLoading,
          "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isPendingTopUpLoading) = $event)),
          "can-cancel": false,
          "is-full-page": false
        }, null, 8, ["active"]))
      : _createCommentVNode("", true),
    (_ctx.pendingUnpaidTopUpList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$gettext("Unpaid top-up requests")), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$gettext("The following top up requests needs to be paid or canceled")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingUnpaidTopUpList, (topup) => {
            return (_openBlock(), _createBlock(_component_TransactionItem, {
              key: topup,
              transaction: topup,
              onClick: ($event: any) => (_ctx.openModal(topup))
            }, null, 8, ["transaction", "onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.pendingPaidTopUpList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$gettext("Top up waiting admin validation")), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$gettext(
          "The following top up have been paid and are waiting for an administrator of your local currency to validate them."
        )), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingPaidTopUpList, (topup) => {
            return (_openBlock(), _createBlock(_component_TransactionItem, {
              key: topup,
              transaction: topup,
              onClick: ($event: any) => (_ctx.openModal(topup))
            }, null, 8, ["transaction", "onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}