import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54742e98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "container mt-5" }
const _hoisted_3 = { class: "card custom-card custom-card-padding custom-card-prefs" }
const _hoisted_4 = { class: "mb-5" }
const _hoisted_5 = { class: "custom-card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.componentDefs, (group) => {
          return (_openBlock(), _createElementBlock("div", {
            key: group.name,
            class: "prefs-group"
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", _hoisted_5, _toDisplayString(group.label()), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.componentDefs, (componentDef) => {
              return (_openBlock(), _createElementBlock("div", { key: componentDef }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(componentDef.component.name), {
                  "prefs-data": componentDef.data
                }, null, 8, ["prefs-data"]))
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ])
  ]))
}